<template>
  <div id ='kb-support'>
    <header class="popup-header">
      <br>
      <h3 class="title" style="font-size: 30px; text-align: center;">재택연수 준수 서약서</h3>
    </header>
    <div class="content-section " style="margin-top:20px">

      <section class="content-item ">
        <br>
        <header class="desc " style="margin:0 20px; text-align: center; display: block; " >
          <p class="title" style="font-weight:bold;display: block; ">KB국민은행의 재택연수 대상 직원은<br> 본 서약서를 숙독하신 후 동의하시기 바랍니다.</p>
        </header>
        <br>

        <header class="desc" style="margin:0 20px;">
          <p class="title" style="display: block; margin-bottom: 10px;font-weight:bold;">1. 본인은 현재 거주지에서 재택연수에 참여하고,<a style="color:red;">연수 장소</a>를 이탈하지 아니한다. 단, 부득이한 사정이 있는 경우 별도의 연수 장소를 사전 신고하고 해당 장소에서 연수에 참여한다.</p>

          <p class="title" style="display: block;margin-bottom: 10px; font-weight:bold;">2. 본인은 연수 참여 중, 운전, 대중교통 이용, 도보 이동 등  <span style="color:red;">일체의 이동 행위</span>를 하지 않는다.</p>


          <p class="title" style="display: block;margin-bottom: 10px; font-weight:bold;">3. 본인은 재택연수 중 연수 본연의 목적에 부합하도록 강의에 성실히 참여하고 고의, 과실,태만 등으로 연수목적에 어긋나는 일이 발생하지 않도록 한다.</p>


          <p class="title" style="display: block;margin-bottom: 10px; font-weight:bold;">4. 본인은 제3자를 통한  <span style="color:red;">대리 출석</span>, 불법프로그램을 활용한  <span style="color:red;">자동 출석체크</span> 등 부적절한 연수 참여를 하지 않는다.</p>


          <p class="title" style="display: block;margin-bottom: 10px; font-weight:bold;">5. 본인은 쌍방향 소통을 위해 재택연수시 <span style="color:red;">화상 연결</span>을 원칙으로 하며, 화상 연결시에도 은행원으로서의 품위를 유지하기로 한다.</p>


          <p class="title" style="display:block;margin-bottom:10px; font-weight:bold;">6. 본인은 상기 사항을 위반하여
            <input v-model="param.text1" class="kb-form-control reg_text" id="" required="" type="text" placeholder="불성실한" data-parsley-id="1" style="padding-right:0px;width:50px;">
            연수 참여가 확인된 경우 근무 태만 등에 따른
            <input v-model="param.text2" class="kb-form-control reg_text" id="text_2" required="" type="text" placeholder="불이익" data-parsley-id="2" style="padding-right:0px;width:50px;">을 감수하기로 한다.
          </p>
          <br>

          <p class="title" style="font-weight:bold;" >상기 사항을 숙지하고 이를 성실히 준수할 것을 확약합니다.</p>
          <br>
          <p class="title" style="font-weight:bold;"> ■ 직원번호 : {{lrnerId}}</p>
          <p class="title" style="font-weight:bold;"> ■ 성명 : {{lrnerNm}}</p>
        </header>


        <br>

        <div class="page-buttons" style="margin-top:20px;" >

            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickPledgeAgree"><span class="text">동의완료</span></a>
            <a href="javascript:" class="kb-btn kb-btn-outline kb-btn-lg"  @click="closeModal"><span class="text">닫기</span></a>
        </div>

        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </section>

    </div>
  </div>
</template>

<script>

import {reactive} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useStore} from "vuex";
const {showConfirm, showMessage} = useAlert();
import {ACT_INSERT_PLEDGE_AGREE} from "@/store/modules/live/live";
// import {getItems} from "@/assets/js/util";

export default {
  name: 'MobileCloudNowPledgeModal',
  components: {},
  // components: {InputSelect, RowIsPastDatepicker},
  props: {
    modelValue: Boolean,
    distCrseSn: String,
    lrnerId: String,
    lrnerNm: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const store = useStore();
    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const param = reactive({
      text1: '',
      text2: ''
    });

    const clickPledgeAgree = () => {
      if (param.text1 === "불성실한" && param.text2 === "불이익") {
        showConfirm({
          text: '서약서를 등록하시겠습니까?',
          callback: savePledgeAgree
        })
      }else if (param.text1 === "" && param.text2 === "") {
        showMessage("6번 항목의 서약서 빈칸(불성실한, 불이익)을<br/> 작성 바랍니다.");
      } else {
        showMessage("서약서 내용(불성실한, 불이익)을<br/> 잘못 작성하였습니다.");
      }
    };

    const savePledgeAgree = () => {
      store.dispatch(`live/${ACT_INSERT_PLEDGE_AGREE}`, {
        distCrseSn: props.distCrseSn,
        lrnerId: props.lrnerId,
        lrnerNm: props.lrnerNm
      }).then(res=> {
        if(res.status && res.status.code == 200) {
          showMessage({
            text: '서약서 작성을 완료했습니다.<br/> 입장하기를 다시 눌러주세요',
            okBtnTitle: '확인',
            callback: () => {
              closeModal();
            },
          });
          // showMessage('서약서 작성을 완료했습니다.<br/> 입장하기를 다시 눌러주세요');
          // closeModal();
        } else {
          showMessage('서약서 작성을 실패했습니다.<br/>관리자에게 문의해주세요');
        }
      }).catch(e => {
        console.error(e);
      });
    }

    return {
      closeModal,
      clickPledgeAgree,
      param
    }
  }
};
</script>