<template>
  <div class="kb" id="kb">
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-live-qr">
      <!-- main-content -->
      <div class="main-content min-component component-padding">
        <div class="qr-container">
          <div class="qr-area">
            <img :src="getThumbUrl(mobileShowQrObj.itnPath)" alt="QR코드" />
          </div>
          <div class="qr-label">출결 QR코드</div>
          <button class="kb-btn-close" @click="closeLayer"><i class="icon-close"></i></button>
        </div>
      </div>
      <!-- //main-content -->
    </main>
    <!-- end::kb-main -->
  </div>
</template>

<script>
import {getThumbUrl} from "@/assets/js/modules/course/course-common";
import {onMounted} from "vue";

export default {
  name: "MobileQrLayer",
  props: {
    isQrShow: Boolean,
    mobileShowQrObj: Object
  },
  emits:['update:isQrShow'],
  setup(props, {emit}){

    onMounted(() => {
      document.getElementById('kb-footer').style.display = 'none'
    })

    const closeLayer = () => {
      emit('update:isQrShow', false);
      document.getElementById('kb-footer').style.display = ''
    }

    return{
      getThumbUrl,
      closeLayer
    }
  }
}
</script>

<style scoped>

</style>